import { computed, reactive } from "vue";
import qtEduPrograms from "./QTEduPrograms.js";

export function useQTEduResources(title) {
  const programs = reactive(qtEduPrograms);

  const program = computed(() => {
    // todo: use ids instead of title, also in router and component
    if (!title) return null;
    return qtEduPrograms.find((p) => p.title === title);
  });

  return {
    program,
    programs,
  };
}
